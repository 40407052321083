

.wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    background: #FFFFFF !important;
    // padding-bottom: 16px;
}

.head_banner_toggle_hide{
    animation: togglehide 0.2s linear;
    animation-fill-mode: forwards;
}
@keyframes togglehide {
    0% {
        height: 197px;
    }
    100% {
        height: 94px;
    }
}

.head_banner_toggle_show{
    animation: toggleshow 0.2s linear;
    animation-fill-mode: forwards;
}
@keyframes toggleshow {
    0% {
        height: 94px;
    }
    100% {
        height: 197px;
    }
}

.head_banner{
    position: fixed;
    background-color: #FADF4A;
    height: 197px;
    width: 100%;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    text-align: center;
    top: 0;
    transition: 0.1s;
    overflow: hidden;
    z-index: 1;
    box-shadow: 2px 2px 2px rgba(169, 168, 168, 0.6);
}
.head_banner > .back > img{
    position: absolute;
    width: 40px;
    height: 40px;
    left: 16px;
    top: 44px;
    cursor: pointer;
}

.head_banner > .headline > img{
    position: absolute;
    width: 56px;
    height: 56px;
    left: 20px;
    top: 100px;
}
.head_banner > .headline > h3{
    position: absolute;
    left: 87px;
    top: 96px;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
}
.head_banner > .headline > h2{
    position: absolute;
    left: 87px;
    top: 127px;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
}

.container{
    position: relative;
    min-height: 800px;
    margin-top: 203px;
    // margin-left: 12px;
    // margin-right: 12px;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    background: #FFFFFF !important;
}

.hot_event_list{
    position: relative;
}

.hot_event_item{
    position: relative;
    margin-left: 16px;
    margin-top: 16px;
    margin-right: 16px;
}

.hot_event_item > .hot_event_headline {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
}

    .hot_event_item > .hot_event_headline >img{
        width: 30px;
        height: 30px;
        display: flex;
    }

    .hot_event_item > .hot_event_headline >h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        margin-left: 4px;
        display: flex;
    }
.hot_event_listing{
    display: flex;
    overflow-y: scroll;
    cursor: pointer;
}

.hot_event_listing > .hot_event{
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    margin-top: 16px;
    text-align: left;
    overflow: hidden;
    padding-bottom: 16px;
    width: 100%;
}

    .hot_event_listing > .hot_event img{
        width: 100%;
    }
    .hot_event_listing > .hot_event >h2{
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-left: 16px;
        margin-top: 16px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .hot_event_listing > .hot_event >p{
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-left: 16px;
        margin-right: 16px;
        height: 40px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

.event_listing{
    position: relative;
    margin-top: 17px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
    .event_listing > .event_item{
        position: relative;
        display: flex;
        height: 100px;
        margin-bottom: 32px;
        flex: 1 0;
        cursor: pointer;
    }
    .event_listing > .event_item > .event_thumbnail{
        text-align: left;
        overflow: hidden;
        border-radius: 4px;
        width: 100px;
    }
    .event_listing > .event_item > .event_thumbnail >img{
        width: 100px;
        height: 100px;
    }
    .event_listing > .event_item > .event_content{
        clear: both;
        position: relative;
        text-align: left;
        margin-left: 16px;
        width: 204px;
    }
    .event_listing > .event_item > .event_content > h2{
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-top: 0px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .event_listing > .event_item > .event_content > p{
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 0px;
        height: 40px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .event_listing > .event_item > .event_content > .timing{
        position: absolute;
        margin-bottom: 0px;
        bottom: 0px;
        margin-left: 0px;
    }
    .event_listing > .event_item > .event_content > .timing_expired{
        position: absolute;
        margin-bottom: 0px;
        bottom: 0px;
        margin-left: 0px;
    }

.timing{
    background-color: #61CB2F;
    border-radius: 100px;
    height: 26px;
    margin-left: 16px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    width: fit-content;
}
.timing > img{
    width: 16px !important;
    height: 16px;
    margin-left: 15px;
}   
.timing > p{
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
    padding-right: 12px;
    padding-top: 1px;
    color: white;
}

.timing_expired{
    background-color: #F7F7F7;
    border-radius: 100px;
    height: 26px;
    margin-left: 16px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    width: fit-content;
}
.timing_expired > img{
    width: 16px !important;
    height: 16px;
    margin-left: 15px;
}   
.timing_expired > p{
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
    padding-right: 12px;
    color: #878787;
    padding-top: 1px;
}

.timing_incomming{
    background-color: #EFEFEF;
    border-radius: 100px;
    height: 26px;
    margin-left: 16px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    width: fit-content;
}
.timing_incomming > img{
    width: 16px !important;
    height: 16px;
    margin-left: 15px;
}   
.timing_incomming > p{
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
    padding-right: 12px;
    color: #61CB2F;
    padding-top: 1px;
}

.btn_more_event{
    position: relative;
    height: 44px;
    background-color: #F7F7F7;
    border: 0px;
    width: 100%;
    border-radius: 28px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
    .btn_more_event > i{
        color: #FADF4A;
        font-size: 18px;
        font-weight: 700;
        padding-left: 9px;
    }

.incomming_event_list{
    position: relative;
    margin-top: 32px;
    padding-bottom: 16px;
}

.incomming_event_item{
    position: relative;
    margin-left: 16px;
    margin-top: 16px;
    margin-right: 16px;
}
.incomming_event_item > .incomming_event_headline {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
}

    .incomming_event_item > .incomming_event_headline >img{
        width: 30px;
        height: 30px;
    }

    .incomming_event_item > .incomming_event_headline >h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        margin-left: 4px;
    }
    .incomming_event_item > .incomming_event_listing{
        margin-top: 16px;
        margin-left: 0px;
        border-radius: 10px;
        display: flex;
        overflow-y: auto;
        flex: 1;
        gap: 20px;
    }
    .incomming_event_item > .incomming_event_listing > .incomming_event{
        flex-direction: row;
        text-align: left;
        padding-bottom: 16px;
        border: 1px solid #F0F0F0;
        border-radius: 10px;
        width: 260px;
        flex: 0 0 260px;
        cursor: pointer;
    }
    .incomming_event_item > .incomming_event_listing > .incomming_event > img{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 130px;
        width: 100%;
    }
    .incomming_event_item > .incomming_event_listing > .incomming_event > h2{
        margin-left: 16px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .incomming_event_item > .incomming_event_listing > .incomming_event > p{
        margin-left: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        height: 40px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

.btn:hover{
    background-color: #FADF4A;
}
.btn:hover i{
    color: white;
}