* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: #fadf4a;
  height: 100vh;
  width: 100%;
}

.head_section img {
  width: 40px;
  -webkit-filter: drop-shadow(1px 1px 1px #a1a0a0);
  filter: drop-shadow(1px 1px 1px #a1a0a0);
}

.btn_white {
  background-color: white; /* Green */
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  border-radius: 100px;
  width: 330px;
  height: 56px;
  margin-bottom: 6px;
}
.full_close_box {
  border: 0px solid red;
}
.gift_section {
  animation: gift-zoom 2s ease infinite;
}

.cai_no {
  animation: no-shaking 0.5s linear infinite;
  animation-delay: 0.1s;
}

.full_nap {
  animation: no-shaking 0.3s linear infinite;
}

.open-nap {
  animation: open-nap 0.5s linear forwards;
}

.reward_out {
  animation: reward-out 1.5s ease forwards;
}

.reward p {
  z-index: 0;
  color: mix(yellow, #ffdb29, 80%);
  &:before {
    text-shadow: #712f00 3px 0px 0px, #712f00 2.83487px 0.981584px 0px, #712f00 2.35766px 1.85511px 0px, #712f00 1.62091px 2.52441px 0px, #712f00 0.705713px 2.91581px 0px, #712f00 -0.287171px 2.98622px 0px, #712f00 -1.24844px 2.72789px 0px, #712f00 -2.07227px 2.16926px 0px, #712f00 -2.66798px 1.37182px 0px, #712f00 -2.96998px 0.42336px 0px, #712f00 -2.94502px -0.571704px 0px, #712f00 -2.59586px -1.50383px 0px, #712f00 -1.96093px -2.27041px 0px, #712f00 -1.11013px -2.78704px 0px, #712f00 -0.137119px -2.99686px 0px, #712f00 0.850987px -2.87677px 0px, #712f00 1.74541px -2.43999px 0px, #712f00 2.44769px -1.73459px 0px, #712f00 2.88051px -0.838247px 0px;
    content: attr(title);
    position: absolute;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: attr(title);
    z-index: 1;
    background: linear-gradient(187deg, #FCFF81 17.77%, #FFAA06 171.89%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.reward .shadow-item {
  z-index: -1;
  text-shadow: 0px 6px 7px rgba(0, 0, 0, 0.25);
}

.full_box img {
  display: inline-block;
}

@keyframes no-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes gift-zoom {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  30% {
    transform: scale(0.95, 1.05) translateY(-10px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes open-nap {
  0% {
    transform: translateY(0px) translateX(0px) rotate(0deg);
  }
  20% {
    transform: translateY(-40px) translateX(-20px) rotate(-15deg);
  }
  40% {
    transform: translateY(-60px) translateX(-40px) rotate(-30deg);
  }
  60% {
    transform: translateY(-80px) translateX(-80px) rotate(-45deg);
  }
  80% {
    transform: translateY(-60px) translateX(-100px) rotate(-60deg);
  }
  100% {
    transform: translateY(-30px) translateX(-150px) rotate(-90deg);
  }
}
@keyframes reward-out {
  0% {
    transform: translateY(-4vh) scale(0.3, 0.3);
  }
  100% {
    transform: translateY(-30vh) scale(1, 1);
  }
}
