@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Holtwood+One+SC&family=Kadwa:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/styles/spinner.scss';
@import './assets/styles/main.scss';
@import './assets/styles/gift.scss';

@layer base {

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Plus Jakarta Sans', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
    color: #000000;
  }
}